import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Index = () => {
    const [popupState, setPopupState] = useState(false);
    return (
        <>
            {(popupState) ? (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="bg-black bg-opacity-50 absolute inset-0">
                        <div class="relative mx-auto p-4 w-full max-w-md max-h-full">
                            <div class="relative bg-white rounded-xl shadow dark:bg-gray-700">
                                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                    <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                        Help
                                    </h3>
                                    <button onClick={() => { setPopupState(false) }} type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span class="sr-only">Close modal</span>
                                    </button>
                                </div>
                                <div class="p-4 md:p-5">
                                    <section class="bg-white dark:bg-gray-900">
                                        <div class="container mx-auto">
                                            <div>
                                                <p class="mt-3 font-semibold text-gray-800 dark:text-gray-400">Facing any problem? Feel fee to contact.</p>
                                            </div>

                                            <div class="grid grid-cols-1 mt-6 gap-4 sm:grid-cols-2 lg:grid-cols-1">
                                                <div>
                                                    <span class="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                                        </svg>
                                                    </span>

                                                    <h2 class="mt-4 text-base font-medium text-gray-800 dark:text-white">Email</h2>
                                                    <p class="mt-2 text-sm text-blue-500 dark:text-blue-400">adityadubey.64485@gmail.com</p>
                                                </div>

                                                <div>
                                                    <span class="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                                        </svg>
                                                    </span>

                                                    <h2 class="mt-4 text-base font-medium text-gray-800 dark:text-white">Office</h2>
                                                    <p class="mt-2 text-sm text-blue-500 dark:text-blue-400">Block 30 - 407, LPU</p>
                                                </div>

                                                <div>
                                                    <span class="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                                        </svg>
                                                    </span>

                                                    <h2 class="mt-  text-base font-medium text-gray-800 dark:text-white">Phone</h2>
                                                    <p class="mt-2 text-sm text-blue-500 dark:text-blue-400">+91 9015090561</p>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>) : ('')}



            <nav class="fixed top-0 w-full shadow-md z-10 bg-white dark:bg-gray-950 dark:border-gray-700 "
                style={{
                    backgroundImage: "url('/images/bg_img.svg')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }} >
                <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4">
                    <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src="/images/lpu.svg" class="h-20" alt="" />
                    </a>

                    <div class="hidden w-full md:block md:w-auto" id="navbar-solid-bg">
                        <span
                            class="self-center text-2xl font-semibold whitespace-nowrap text-gray-700 dark:text-white">
                            Samarthya
                        </span>
                    </div>
                    <span class="flex items-center space-x-3 rtl:space-x-reverse">
                        <button onClick={() => { setPopupState(true) }} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                            Help
                        </button>
                    </span>
                </div>
            </nav>
            <main>

                <div class="relative mt-28 mb-12" id="Apps">
                    <div class="mx-auto max-w-6xl px-6 md:px-12 lg:px-6 xl:px-0">
                        <div class="mt-12">
                            <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                <Link to={'/strategy'}>
                                    <div class="w-full max-w-sm bg-white border border-gray-200 rounded-2xl transition duration-500  hover:shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                        <div class="flex justify-end px-4 pt-4">
                                            <div class=" flex text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-1.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" type="button">
                                                <div className='inline w-3 h-3 bg-green-500 rounded-full mt-1 me-1'></div>
                                                <span>Online</span>
                                            </div>
                                        </div>
                                        <div class="flex flex-col items-center pb-10">
                                            <div className='bg-slate-200 w-24 h-24 mb-3 rounded-full shadow-sm p-6'>
                                            <svg class="w-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="#EF7D00"><path d="M220-520 80-600v-160l140-80 140 80v160l-140 80Zm0-92 60-34v-68l-60-34-60 34v68l60 34Zm440 123v-93l140 82v280L560-80 320-220v-280l140-82v93l-60 35v188l160 93 160-93v-188l-60-35Zm-140 89v-480h360l-80 120 80 120H600v240h-80Zm40 69ZM220-680Z"/></svg>
                                            {/* <img class="w-100" src="images/countdown.png" alt="Countdown" /> */}
                                            </div>
                                            <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">Strategy</h5>
                                            <span class="text-sm text-gray-500 dark:text-gray-400 p-2">Communication Strategy for 2025</span>

                                        </div>
                                    </div>
                                </Link>

                                <Link to={'/countdown'}>
                                    <div class="w-full max-w-sm bg-white border border-gray-200 rounded-2xl transition duration-500  hover:shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                        <div class="flex justify-end px-4 pt-4">
                                            <div class=" flex text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-1.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" type="button">
                                                <div className='inline w-3 h-3 bg-green-500 rounded-full mt-1 me-1'></div>
                                                <span>Online</span>
                                            </div>
                                        </div>
                                        <div class="flex flex-col items-center pb-10">
                                            <div className='bg-slate-200 w-24 h-24 mb-3 rounded-full shadow-sm p-6'>
                                                <img class="w-100" src="images/countdown.png" alt="Countdown" />
                                            </div>
                                            <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">Countdown</h5>
                                            <span class="text-sm text-gray-500 dark:text-gray-400 p-2">Dynamic Email Timers Platform</span>

                                        </div>
                                    </div>
                                </Link>

                                <a href={'https://occlpu.webhizzy.in/'} target='_blank'>
                                    <div class="w-full max-w-sm bg-white border border-gray-200 rounded-2xl transition duration-500  hover:shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                        <div class="flex justify-end px-4 pt-4">
                                            <div class=" flex text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-1.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" type="button">
                                                <div className='inline w-3 h-3 bg-green-500 rounded-full mt-1 me-1'></div>
                                                <span>Online</span>
                                            </div>
                                        </div>
                                        <div class="flex flex-col items-center pb-10">
                                            <div className='bg-slate-200 w-24 h-24 mb-3 rounded-full shadow-sm p-6'>
                                                <img class="w-100" src="/images/folder.png" alt="Master Repository" />
                                            </div>
                                            <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">Master Repository</h5>
                                            <span class="text-sm text-gray-500 dark:text-gray-400 p-2">Shared Templates Repository</span>

                                        </div>
                                    </div>
                                </a>

                                <a href={'https://drivelpu.webhizzy.in/'} target='_blank'>
                                    <div class="w-full max-w-sm bg-white border border-gray-200 rounded-2xl transition duration-500  hover:shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                        <div class="flex justify-end px-4 pt-4">
                                            <div class=" flex text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-1.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" type="button">
                                                <div className='inline w-3 h-3 bg-green-500 rounded-full mt-1 me-1'></div>
                                                <span>Online</span>
                                            </div>
                                        </div>
                                        <div class="flex flex-col items-center pb-10">
                                            <div className='bg-slate-200 w-24 h-24 mb-3 rounded-full shadow-sm p-6'>
                                                <img class="w-100" src="/images/cloud.png" alt="Data Drive" />
                                            </div>
                                            <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">Digital Workspace</h5>
                                            <span class="text-sm text-gray-500 dark:text-gray-400 p-2">All Your Files, Notes, Tasks in One Place</span>
                                        </div>
                                    </div>
                                </a>

                                <Link to={'/analytics'}>
                                    <div class="w-full max-w-sm bg-white border border-gray-200 rounded-2xl transition duration-500  hover:shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                        <div class="flex justify-end px-4 pt-4">
                                            <div class=" flex text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-1.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" type="button">
                                                <div className='inline w-3 h-3 bg-green-500 rounded-full mt-1 me-1'></div>
                                                <span>Online</span>
                                            </div>
                                        </div>
                                        <div class="flex flex-col items-center pb-10">
                                            <div className='bg-slate-200 w-24 h-24 mb-3 rounded-full shadow-sm p-6'>
                                                <img class="w-100" src="/images/data-analytics.png" alt="Analytics" />
                                            </div>
                                            <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">Analytics</h5>
                                            <span class="text-sm text-gray-500 dark:text-gray-400 p-2">Communication Reports and Analytics</span>

                                        </div>
                                    </div>
                                </Link>

                                <a href={'https://occlpu.webhizzy.in/data-validation/index.html'} target='_blank'>
                                    <div class="w-full max-w-sm bg-white border border-gray-200 rounded-2xl transition duration-500  hover:shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                        <div class="flex justify-end px-4 pt-4">
                                            <div class=" flex text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-1.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" type="button">
                                                <div className='inline w-3 h-3 bg-green-500 rounded-full mt-1 me-1'></div>
                                                <span>Offline</span>
                                            </div>
                                        </div>
                                        <div class="flex flex-col items-center pb-10">
                                            <div className='bg-slate-200 w-24 h-24 mb-3 rounded-full shadow-sm p-6'>
                                                <img class="w-100" src="/images/audit.png" alt="Validator" />
                                            </div>
                                            <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">Validator</h5>
                                            <span class="text-sm text-gray-500 dark:text-gray-400 p-2">Bulk Data Validation & Cleaning</span>

                                        </div>
                                    </div>
                                </a>

                                <a href={'#'} target='_blank'>
                                    <div class="w-full max-w-sm bg-white border border-gray-200 rounded-2xl transition duration-500  hover:shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                        <div class="flex justify-end px-4 pt-4">
                                            <div class=" flex text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-1.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" type="button">
                                                <div className='inline w-3 h-3 bg-red-500 rounded-full mt-1 me-1'></div>
                                                <span>Offline</span>
                                            </div>
                                        </div>
                                        <div class="flex flex-col items-center pb-10">
                                            <div className='bg-slate-200 w-24 h-24 mb-3 rounded-full shadow-sm p-6'>
                                                <img class="w-100" src="/images/WhatsApp.svg" alt="Mailer Repository" />
                                            </div>
                                            <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">Whatsapp Blast</h5>
                                            <span class="text-sm text-gray-500 dark:text-gray-400 p-2">A Whatsapp Bulk Messaging Bot</span>

                                        </div>
                                    </div>
                                </a>

                                <Link to={'/qr'}>
                                    <div class="w-full max-w-sm bg-white border border-gray-200 rounded-2xl transition duration-500  hover:shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                        <div class="flex justify-end px-4 pt-4">
                                            <div class=" flex text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-1.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" type="button">
                                                <div className='inline w-3 h-3 bg-green-500 rounded-full mt-1 me-1'></div>
                                                <span>Online</span>
                                            </div>
                                        </div>
                                        <div class="flex flex-col items-center pb-10">
                                            <div className='bg-slate-200 w-24 h-24 mb-3 rounded-full shadow-sm p-6'>
                                                <img class="w-100" src="/images/qr.png" alt="QR" />
                                            </div>
                                            <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">QR Generator</h5>
                                            <span class="text-sm text-gray-500 dark:text-gray-400 p-2">Generate QR for text, links & Whatsapp</span>

                                        </div>
                                    </div>
                                </Link>

                                <a href={'https://chat.openai.com/g/g-nERXnPdPt-flowy-admission'} target='_blank'>
                                    <div class="w-full max-w-sm bg-white border border-gray-200 rounded-2xl transition duration-500  hover:shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                        <div class="flex justify-end px-4 pt-4">
                                            <div class=" flex text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-1.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" type="button">
                                                <div className='inline w-3 h-3 bg-yellow-500 rounded-full mt-1 me-1'></div>
                                                <span>Archived</span>
                                            </div>
                                        </div>
                                        <div class="flex flex-col items-center pb-10">
                                            <div className='bg-slate-200 w-24 h-24 mb-3 rounded-full shadow-sm p-6'>
                                                <img class="w-100" src="/images/bot.png" alt="Flowy" />
                                            </div>
                                            <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">Flowy</h5>
                                            <span class="text-sm text-gray-500 dark:text-gray-400 p-2">A Chat GPT based Admission Assistant. </span>

                                        </div>
                                    </div>
                                </a>

                                <a href={'https://occlpu.webhizzy.in/mailer-toolkit.html'} target='_blank'>
                                    <div class="w-full max-w-sm bg-white border border-gray-200 rounded-2xl transition duration-500  hover:shadow-lg dark:bg-gray-800 dark:border-gray-700">
                                        <div class="flex justify-end px-4 pt-4">
                                            <div class=" flex text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-1.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" type="button">
                                                <div className='inline w-3 h-3 bg-yellow-500 rounded-full mt-1 me-1'></div>
                                                <span>Archived</span>
                                            </div>
                                        </div>
                                        <div class="flex flex-col items-center pb-10">
                                            <div className='bg-slate-200 w-24 h-24 mb-3 rounded-full shadow-sm p-6'>
                                                <img class="w-100" src="/images/mockup.png" alt="Flowy" />
                                            </div>
                                            <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">Mailer Toolkit</h5>
                                            <span class="text-sm text-gray-500 dark:text-gray-400 p-2">Email Component Library </span>

                                        </div>
                                    </div>
                                </a>

                            </div>

                        </div>
                    </div>
                </div>
            </main>




            <footer class="bg-white border-t-2 border-gray-100 dark:bg-gray-900"
                style={{
                    backgroundImage: "url('/images/bg_img.svg')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }} >
                <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <div class="sm:flex sm:items-center sm:justify-between">
                        <a href="#" class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                            <img src="/images/LPU-logo.svg" class="h-28" alt="LPU Logo" />

                        </a>
                        <ul
                            class="flex flex-wrap items-center mb-6 text-md font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                            <li>
                                <a href="#" class="hover:underline me-4 md:me-6">About</a>
                            </li>
                            <li>
                                <a href="#" class="hover:underline me-4 md:me-6">Server Status</a>
                            </li>
                            <li>
                                <span onClick={() => { setPopupState(true) }} class="hover:underline cursor-pointer">Contact</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Index;
